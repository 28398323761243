<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2 px-2">
        <b-col
          cols="12"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableBorrowings.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="px-1"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="state.busy"
            @click="onAddBorrowing"
          >
            Borrow Item
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="tableBorrowings"
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tableBorrowings.perPage"
        :current-page="tableBorrowings.currentPage"
        :items="tableBorrowingProvider"
        :fields="tableBorrowings.fields"
        :sort-by.sync="tableBorrowings.sortBy"
        :sort-desc.sync="tableBorrowings.sortDesc"
        :sort-direction="tableBorrowings.sortDirection"
        :filter="tableBorrowings.filter"
        :filter-included-fields="tableBorrowings.filterOn"
      >
        <template #cell(index)="row">
          {{ tableBorrowings.currentPage * tableBorrowings.perPage - tableBorrowings.perPage + (row.index + 1) }}
        </template>

        <template #cell(action)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              variant="success"
              :disabled="state.busy"
              @click="onEditBorrowing(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableBorrowings.perPage"
              :options="tableBorrowings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableBorrowings.currentPage"
            :total-rows="tableBorrowings.totalRows"
            :per-page="tableBorrowings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-borrowing"
      size="xl"
      scrollable
      no-close-on-backdrop
      :title="ModalBorrowingTitle"
      aria-hidden="false"
      @ok="onValidateBorrowingForm"
    >
      <b-row>
        <!-- Inputs -->
        <b-col
          cols="12"
          md="4"
        >
          <ValidationObserver
            ref="formBorrowing"
          >
            <form
              novalidate
              @submit.prevent
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="employee"
                    vid="employee"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="employee">
                        <strong>
                          Employee
                        </strong>
                      </label>
                      <v-select
                        id="employee"
                        v-model="selected.employee"
                        type="text"
                        label="employee_name"
                        placeholder="search employee here"
                        :options="list.employees"
                        :class="[errors.length > 0 ? 'is-invalid' : null]"
                        :disabled="state.busy || fetching.employees || state.editing"
                        :loading="fetching.employees"
                      >
                        <template #option="{ employee_name, employee_code }">
                          <div class="d-flex flex-column">
                            <strong>{{ employee_name }}</strong>
                            <span>{{ employee_code }}</span>
                          </div>
                        </template>
                        <template #no-options="">
                          no available employee
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="start date"
                    vid="start_date"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="start_date">
                        <strong>
                          Start
                        </strong>
                      </label>
                      <b-input
                        id="start_date"
                        v-model="borrowing.start_date"
                        type="datetime-local"
                        autocomplete="off"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="end date"
                    vid="end_date"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="end_date">
                        <strong>
                          End
                        </strong>
                      </label>
                      <b-input
                        id="end_date"
                        v-model="borrowing.end_date"
                        type="datetime-local"
                        autocomplete="off"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="purpose"
                    vid="purpose"
                    rules="required|max:500"
                  >
                    <b-form-group>
                      <label for="purpose">
                        <strong>
                          Purpose
                        </strong>
                      </label>
                      <b-textarea
                        id="purpose"
                        v-model="borrowing.purpose"
                        rows="6"
                        max-rows="12"
                        placeholder="enter purpose"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy || state.viewing || state.editing"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

              </b-row>
            </form>
          </ValidationObserver>
        </b-col>

        <b-col
          cols="12"
          md="8"
        >
          <ValidationObserver
            ref="formBorrowingItem"
          >
            <form
              novalidate
              @submit.prevent
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="template"
                    vid="template"
                    rules="required"
                  >
                    <b-form-group>
                      <label
                        class="col-12 px-0"
                        for="template"
                      >
                        <span class="d-flex justify-content-between">
                          <strong>
                            Template
                          </strong>
                          <i>
                            Remaining: <b class="text-success">{{ selected.template.items_count }}</b>
                          </i>
                        </span>
                      </label>
                      <b-form-select
                        id="template"
                        v-model="selected.template"
                        :options="list.templates"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy || state.editing"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="{
                              id: null,
                              template_name: null,
                              items_count: 0
                            }"
                            disabled
                          >
                            -- select template --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- Composition Tables -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-table
                    ref="tableBorrowingItemsCompositions"
                    hover
                    responsive
                    show-empty
                    class="mt-2"
                    :items="compositionFilter"
                    :fields="tableBorrowingItems.compositions.fields"
                  >

                    <template #head(action)="row">
                      <div class="text-nowrap text-center">
                        {{ row.label }}
                      </div>
                    </template>

                    <template #head(item_details_count)="row">
                      <div class="text-nowrap text-center">
                        {{ row.label }}
                      </div>
                    </template>

                    <template #cell(item_details_count)="row">
                      <div class="text-nowrap text-center">
                        <strong>{{ row.value }}</strong>
                      </div>
                    </template>

                    <template #cell(action)="row">
                      <div class="text-nowrap text-center">
                        <b-button
                          size="sm"
                          variant="success"
                          @click="onAddBorrowingItem(row.item)"
                        >
                          Add
                        </b-button>
                      </div>
                    </template>
                    <template #cell()="row">
                      <div class="text-nowrap">
                        {{ row.value }}
                      </div>
                    </template>
                  </b-table>
                </b-col>

                <!-- Item Tables -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-table
                    ref="tableBorrowingItemsItems"
                    hover
                    responsive
                    show-empty
                    class="mt-2"
                    :items="borrowing.items"
                    :fields="tableBorrowingItems.items.fields.filter(th => (state.editing ? th.key !== 'action' : th))"
                  >

                    <template #head(action)="row">
                      <div class="text-nowrap text-center">
                        {{ row.label }}
                      </div>
                    </template>

                    <template #cell(action)="row">
                      <div class="text-nowrap text-center">
                        <b-button
                          size="sm"
                          variant="danger"
                          @click="onRemoveBorrowingItem(row.item)"
                        >
                          Remove
                        </b-button>
                      </div>
                    </template>

                    <template #cell()="row">
                      <div class="text-nowrap">
                        {{ row.value }}
                      </div>
                    </template>

                  </b-table>
                </b-col>

              </b-row>
            </form>
          </ValidationObserver>
        </b-col>

      </b-row>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AdminUserBorrowingService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminBorrowing',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Borrow Item'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      fetching: {
        employees: false,
        templates: false,
        compositions: false
      },
      filter: {
        template: 'All'
      },
      list: {
        templates: [],
        compositions: [],
        employees: []
      },
      selected: {
        template: {
          id: null,
          template_name: null,
          items_count: 0
        },
        employee: null
      },
      borrowing: {
        id: null,
        employee: null,
        employee_name: null,
        start_date: null,
        end_date: null,
        purpose: null,
        status: 'Pending',
        items: []
      },
      tableBorrowings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          'index',
          { key: 'action', class: 'text-center' },
          { key: 'employee_name', label: 'employee' },
          { key: 'start_date', formatter: this.dateTimeShortFormatter },
          { key: 'end_date', formatter: this.dateTimeShortFormatter },
          { key: 'status', class: 'text-center' },
          { key: 'created_at', formatter: this.dateTimeShortFormatter }
        ]
      },
      tableBorrowingItems: {
        compositions: {
          fields: [
            { key: 'action', class: 'text-center' },
            { key: 'category_name', label: 'category' },
            { key: 'item_details_count', label: 'remaining' }
          ]
        },
        items: {
          fields: [
            { key: 'action', class: 'text-center' },
            { key: 'template_name', label: 'template' },
            { key: 'category_name', label: 'category' }
          ]
        }
      }
    }
  },

  computed: {

    ModalBorrowingTitle () {
      return this.state.editing ? 'Edit Borrowed Item' : 'Add Borrowing Items'
    },

    compositionFilter () {
      return this.list.compositions.filter(
        composition => !this.borrowing.items.filter(
          item => item.template_id === this.selected.template.id
        ).map(item => item.category_id).includes(composition.category_id)
      )
    }

  },

  watch: {

    'selected.template' (template) {
      if (template?.id) {
        this.getTemplateComposition(template.id)
      } else {
        this.list.compositions = []
      }
    },

    'selected.employee' (employee) {
      if (employee?.id) {
        this.borrowing.employee = employee.id
        this.borrowing.employee_name = employee.employee_name
      } else {
        this.list.compositions = []
      }
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    async tableBorrowingProvider (option) {
      return await AdminUserBorrowingService.get(
        this.objectToUrl({
          page: option.currentPage,
          per_page: option.perPage,
          sort: option.sortBy,
          sort_desc: option.sortDesc,
          filter_text: option.filter
        })
      ).then(({ data }) => {
        this.tableBorrowings.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    async getEmployees () {
      this.fetching.employees = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getEmployees().then(({ data }) => {
          this.list.employees = data.map(
            employee => ({
              id: employee.id,
              employee_code: employee.employee_code,
              employee_name: employee.employee_name
            })
          )
          this.fetching.employees = this.state.busy = false
          resolve(data)
        })
      })
    },

    async getTemplates () {
      this.fetching.templates = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getTemplates('with_item_count=1').then(({ data }) => {
          this.list.templates = data.filter(template => template.items_count > 0).map(({ id, template_name, items_count }) => ({
            text: template_name,
            value: {
              id: id,
              template_name: template_name,
              items_count: items_count
            }
          }))
          this.fetching.templates = this.state.busy = false
          resolve(data)
        })
      })
    },

    async getTemplateComposition (template) {
      this.fetching.compositions = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getTemplateComposition(`template=${template}`).then(({ data }) => {
          this.list.compositions = data.filter(
            composition => (composition.item_details_count > 0 && composition.is_package === 0) || composition.is_parent === 1
          )
          this.fetching.compositions = this.state.busy = false
          resolve(data)
        })
      })
    },

    onAddBorrowing () {
      this.state.editing = false

      this.borrowing.id = null
      this.borrowing.employee = null
      this.borrowing.employee_name = null
      this.borrowing.start_date = null
      this.borrowing.end_date = null
      this.borrowing.purpose = null
      this.borrowing.items = []
      this.borrowing.status = 'Pending'

      this.list.compositions = []

      this.selected.employee = null

      this.selected.template = {
        id: null,
        template_name: null,
        items_count: 0
      }

      this.getEmployees().then(() => {
        this.getTemplates().then(() => {
          this.$bvModal.show('modal-borrowing')
        })
      })
    },

    onEditBorrowing (borrowing) {
      this.state.editing = true

      this.borrowing.id = borrowing.id
      this.borrowing.employee = borrowing.employee_id
      this.borrowing.employee_name = borrowing.employee_name
      this.borrowing.start_date = this.dateTimeLocalFormatter(borrowing.start_date)
      this.borrowing.end_date = this.dateTimeLocalFormatter(borrowing.end_date)
      this.borrowing.purpose = borrowing.purpose
      this.borrowing.status = borrowing.status

      this.selected.template = {
        id: null,
        template_name: null,
        items_count: 0
      }

      const collection = []

      borrowing.borrowing_items.forEach(bi => {
        collection.push({
          id: bi.id,
          template_id: bi.template_id,
          template_name: bi.template_name,
          category_id: bi.category_id,
          category_name: bi.category_name
        })

        borrowing.borrowing_item_details.filter(filter => filter.borrowing_item_id === bi.id).forEach(bid => {
          collection.push({
            id: bid.id,
            template_id: bid.template_id,
            template_name: bid.template_name,
            category_id: bid.category_id,
            category_name: bid.category_name
          })
        })
      })

      borrowing.borrowing_item_details.filter(filter => !collection.map(collected => collected.id).includes(filter.id)).forEach(bid => {
        collection.push({
          id: bid.id,
          template_id: bid.template_id,
          template_name: bid.template_name,
          category_id: bid.category_id,
          category_name: bid.category_name
        })
      })

      this.borrowing.items = collection

      this.getEmployees().then(() => {
        const position = this.list.employees.findIndex(
          employee => employee.id === borrowing.employee_id
        )
        if (position >= 0) {
          this.selected.employee = this.list.employees[position]
        }

        this.$bvModal.show('modal-borrowing')
      })
    },

    onAddBorrowingItem (borrowingItem) {
      const validation = this.borrowing.items.findIndex(
        item => item.template_id === this.selected.template.id && item.category_id === borrowingItem.category_id
      )
      if (validation >= 0) {
        this.$refs.formBorrowingItem.setErrors({
          template: [
            'The category already exists.'
          ]
        })
      } else {
        this.borrowing.items.push({
          template_id: this.selected.template.id,
          template_name: this.selected.template.template_name,
          category_id: borrowingItem.category_id,
          category_name: borrowingItem.category_name,
          is_parent: borrowingItem.is_parent
        })
      }
    },

    onRemoveBorrowingItem (borrowingItem) {
      const removableBorrowingItem = this.borrowing.items.findIndex(
        item => item.category_id === borrowingItem.category_id && item.template_id === borrowingItem.template_id
      )
      if (removableBorrowingItem >= 0) {
        this.borrowing.items.splice(removableBorrowingItem, 1)
      }
    },

    async onValidateBorrowingForm (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formBorrowing.validate().then(async success => {
        if (success && this.borrowing.items.length > 0) {
          let text = 'Do you really want to borrow these items?'
          if (this.state.viewing) {
            text = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = false
              if (this.state.editing) {
                return this.onPutBorrowingForm()
              } else {
                return this.onPostBorrowingForm()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          if (this.borrowing.items.length <= 0) {
            this.$refs.formBorrowingItem.setErrors({
              template: [
                'Please add atleast one category.'
              ]
            })
          }

          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPostBorrowingForm () {
      return new Promise(resolve => {
        AdminUserBorrowingService.post(this.borrowing).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-borrowing')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableBorrowings.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formBorrowing.setErrors(error.message)
          resolve(error)
        })
      })
    },

    async onPutBorrowingForm () {
      return new Promise(resolve => {
        AdminUserBorrowingService.put(this.borrowing).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-borrowing')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableBorrowings.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formBorrowing.setErrors(error.message)
          resolve(error)
        })
      })
    }

  }
}
</script>
<style>
  #modal-borrowing .modal-xl {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }

  #modal-borrowing .modal-content {
    min-height: 100vh;
  }
</style>
